.location-detail-root{
    position: absolute;
    overflow-y: auto !important;
    top: 75px;
    left: auto;
    right: 20px;
}

#simple-tab-0,
#simple-tab-1,
#simple-tab-2,
#simple-tab-3
{
    border-radius: 50px;
    color: #000 !important;
    min-height: 38px;
    padding-top: 10px;
    padding-bottom: 10px;
    min-width: auto;
}

#simple-tab-0[aria-selected="true"] svg,
#simple-tab-1[aria-selected="true"] svg,
#simple-tab-2[aria-selected="true"] svg,
#simple-tab-3[aria-selected="true"] svg{
    color: #fff !important;
    fill: var(--app-color);
}

#charger-station-simple-tab-0[aria-selected="true"] path,
#charger-station-simple-tab-1[aria-selected="true"] path,
#charger-station-simple-tab-2[aria-selected="true"] path,
#charger-station-simple-tab-3[aria-selected="true"] path{
    /* color: #fff !important; */
   fill: var(--app-color);
}
.location-detail-root 
.MuiTabs-indicator{
    background-color: transparent;
}

.tabs-parent
.MuiTabs-scroller{
    min-height: 38px;
}

.tabs-parent
div[role="tablist"]{
    background-color: #fff;
    border-radius: 50px;
}

.list-card-content{
    padding-top: 0;
}

.item-text
.MuiTypography-body1{
    font-size: 0.7em;
}
.item_text_kw
.MuiTypography-body1{
    font-size: 12px;
}
.item_text_type
.MuiTypography-body1{
    font-size: 14px;
    font-weight: 600;
}
.item_text_status
.MuiTypography-body1{
    font-size: 14px;
}


.tab-list
.css-19kzrtu{
    padding: 15px 0 15px 0;
}

@media screen and (max-width: 420px) {
    .location-detail-root{
        max-width: 100%;
        width: 358px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1060;
    }
    .connectors {
        flex-wrap: wrap;
    }
    .box-button {
        width: 75%;
    }
}

.connector-item-text{
    display: flex;
    justify-content: center;
    align-items: center;
}

.charger-detail-content{
    display: grid;
    grid-auto-rows: 70% 30%;
}
.charger-type-conector{
    display: grid;
    grid-template-columns: 40% 60%;
    place-items: center;
    margin-top: 10px;
}
.charger-connector-image-select{
    width: 45px;
    height: 45px;
    display: flex;
    border: 2px solid #76BC45;
    padding: 6px;
    border-radius: 6px;
    background-color: rgba(161, 223, 28, 0.12);
    margin-left: 5px;
}
.charger-connector-image-unselect{
    width: 45px;
    height: 45px;
    display: flex;
    justify-content:center;
    border: 2px solid #BDBDBD;
    padding: 6px;
    border-radius: 6px;
    margin-left: 5px;
}
#pt-footer .css-1d6wzja-MuiButton-startIcon{
     margin-left: 0px !important; 
}

.connector_type_img_available svg{
    height: 30px;
    width: 30px;
    fill: #76BC45
}

.connector_type_img_unavailable svg{
    height: 30px;
    width: 30px;
    fill: #BDBDBD
}

.css-yw020d-MuiAccordionSummary-expandIconWrapper{
    transform: rotate(270deg) !important;
}

.css-yw020d-MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(0deg) !important;
}

.accordion_content{
    /* box-shadow: none !important; */
    box-shadow: 0px 0px 4px 0px #00000040 !important;
}

.selected_report_issue{
    background: var(--app-color) !important;
    color: #fff !important;
}
.map-connector-type-icon svg{
    width: 30px;
    height: 30px;
}
