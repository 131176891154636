/* Oaza variable css styles */

:root{
    --app-color: #1F5150;
    --app-color-dark: #136f61;
    --app-base: #ffff;
    --text-opacity: 1;
    --text-secondary: rgb(103 128 159/var(--text-opacity));
    --yellow-800: #e7d219;
    --app-color-light: #13324b;
    --google-font: 'Work Sans', sans-serif;
    --demo-blue: rgba(175,195,202,.4);
    --header-height: 68px;
    --header-height-tablet: 64px;
    --header-height-mobile: 56px;
    --fs-xl: 3rem;
    --fs-600: 2rem;
    --fs-400: 1rem;
}
