@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400&display=swap');
@import './styles/base/_variables.css';
@import './styles/base/_colors.css';
@import './styles/base/_utilities.css';
@import "./styles/media/_mobile.css";

html,body, #root{
  height: 100%;
}

img {
  height: auto;
  max-width: 100%;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgb(255 255 255 / 56%);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.map-container {
  width: 100%;
  height: 100vh;
  flex-grow: 1;
}

.icon-close {
  position: absolute;
  right: 4px;
  top: 14px;
}

.layout-grid {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.layout-grid-form {
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  grid-column-gap: 70px;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto;
}

.Map-toolbar{
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 8px;
}

.Map-toolbar .MuiLinearProgress-root{
  position: absolute;
  top: 0;
  display: block;
  width: 100%;
}

.Map-action-button{
  display: flex;
  align-items: center;
  padding: 2px 15px;
  height: 48px;
  line-height: 48px;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  border-radius: 50px !important;
}
.Map-action-button.map-key-info--paper-button:hover,
.Map-action-button.route-planner--paper-button:hover,
.Map-action-button.location-filter--paper-button:hover,
.Map-action-button.map-key-info--paper-button:active,
.Map-action-button.route-planner--paper-button:active,
.Map-action-button.location-filter--paper-button:active {
  background-color: var(--app-color);
  color: var(--app-base)
}

.Map-modal{
  display: inline-flex;
  flex-flow: column;
}

.Map-modal{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--white);
}

.pac-container.pac-logo{
  z-index: 1400;
}

.MuiToggleButton-root.Mui-selected{
  background-color: rgba(134, 187, 69, 0.192) !important;
}

.tab-location,
.tab-info{
  position: relative;
}

.cm-solution-section
.MuiPaper-root {
  background-color: var(--app-color);
  color: #fff;
  height: 60px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cm-solution-section
.MuiPaper-root
h6 {
  line-height: 1;
}
/*
.why-cm-container {
  margin-top: calc(68.5px + 3.25rem);
}
*/

.why-cm-container,
.why-cm-container p,
.why-cm-container h2,
.why-cm-container h4 {
  font-family: var(--google-font);
  color: #848484;
}

.why-cm-container > .MuiGrid-container:not(:nth-child(2)) {
  padding-top: 56px;
}

.why-cm-container p,
.why-cm-container h2 {
  text-align: start;
}

.why-cm-container 
.MuiGrid-item h2 {
  margin-bottom: 18px;
  font-size: var(--fs-600);
}

.why-cm-container 
.MuiGrid-item p {
  color: var(--app-color);
  font-size: var(--fs-400);
}

.why-cm-container .our-feature > p,
.why-cm-container .team-cares > p {
  font-size: var(--fs-400); 
  margin-top: 18px;
  text-align: center;
}

.why-cm-container .our-feature 
> p:not(:first-of-type) {
  margin-top: 0;
}

.why-cm-container 
.MuiGrid-item img {
  border-radius: 10px;
/*  width: -webkit-fill-available; */
}

.cm-apps {
  margin-top: 8px;
}

.cm-apps .sr-only {
  clip: rect(0,0,0,0);
  border-width: 0;
  height: 1px;
  width: 1px;
  padding: 0;
  position: absolute;
  margin: -1px;
  left: -24rem;
  top: auto;
}

footer .cm-apps img {
  width: 12.25rem;
}

header .cm-apps img, 
.MuiDialogActions-root .cm-apps img,
.cm-login-container .cm-apps img,
.cm-register-container .cm-apps img
{
  width: 8.25rem;
  height:2.625rem;
}

.product-main 
.cm-product-mobile-image {
  /* width: 11.55rem; */
}

.product-main
.cm-product-mobile-loop {
  width: 21.55rem;
}

.product-main .image-oaza-benefits img {
  width: 147px;
}

.MuiDialogActions-root.MuiAction-root-custom {
  gap: 32px;
  justify-content: center;
  background-color: #000;
  color: #fff;
}

.cm-login-container .apps-stack,
.cm-register-container .apps-stack
 {
  margin-top: 16px;
}

.section-request-demo {
/*  margin-left: -56px;
 margin-right: -56px; */
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: var(--demo-blue);
}

.section-request-demo .request-demo-grid {
  position: relative;
  display: flex;
  margin: 0 auto;
  max-width: 1200px;
  width: 85%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex: 0 auto;
}

.section-request-demo 
.request-demo-grid
.request-demo-layout
.grid-node {
  -ms-grid-row: 1;
  grid-row-start: span 1;
  -ms-grid-column-span: 1;
  grid-row-end: span 1;
  grid-column-start: span 1;
  grid-column-end: span 1;
} 

.request-demo-layout > .grid-node:first-of-type {
  width: max-content;
}

.request-demo-layout > .grid-node:last-of-type {
  text-align: end;
}

.request-demo-layout > .grid-node:last-of-type a {
  padding: 22px 66px;
}

h1, h2, h3, h4, h5, h6, p, span.MuiTypography-caption, a, div.MuiTypography-root, span.MuiPaper-root, font
{
  font-family: var(--google-font) !important;
}

.mobile-install-widget {
  position: fixed;
  width: 100%;
  top: 0;
  left: auto;
  right: 0;
  z-index: 1200;
  background: #fff;
  margin-bottom: 11px;
  flex-shrink: 0;
  transition: position 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.mobile-install-widget .widget {
  display: flex;
  height: 68px;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  background-color: #fff;
}

.mobile-install-widget .widget img {
  width: 2.95rem;
  border-radius: 10px;
  display: inline-block;
  vertical-align: middle;
  object-fit: cover;
  margin-right: 6px;
}

.mobile-install-widget .widget .MuiPaper-root {
  flex: 1;
  text-align: start;
}

.mobile-install-widget .widget a {
  margin-right: 16px;
}

.mobile-install-widget ~ .MuiContainer-root {
  padding-top: 72px;
}

.widget-open {
  padding-top: 68px;
}

.section-feedback form {
  margin-bottom: 28px;
}

.MuiDrawer-modal,
.MuiDrawer-paper{
  right: calc(100% - 460px) !important;
  top: var(--header-height) !important;
}

.MuiDrawer-paper__content 
.MuiDrawer-paper__trip-planner h5 {
  background-color: var(--app-color);
  color: #fff;
  padding: 1rem;
}

.MuiDrawer-paper__trip-planner
.trip-action a {
  display: inline-block;
  text-align: center;
  line-height: 0;
  text-decoration: none;
  color: var(--app-color);
}

.MuiDrawer-paper__trip-planner
.trip-action a h6 {
  color: #000;
  font-weight: 600;
}

.MuiDrawer-paper__trip-planner
.MuiGrid-item:nth-child(2) {
  background-color: #f7f7f7;
}

.MuiDrawer-paper__trip-planner
.trip-planner__label {
  color: #c3c3c3;
  font-weight: 500;
}

.MuiDrawer-paper__trip-planner
.trip-planner__location {
  color: #000;
  font-weight: 600;
  font-size: 0.9em;
}

.MuiDrawer-paper__trip-planner
.trip-panner__metric-duration svg,
.MuiDrawer-paper__trip-planner 
.trip-planner__duration {
  display: inline-block;
  vertical-align: middle;
}

.MuiDrawer-paper__trip-planner
.trip-panner__metric-duration svg,
.MuiDrawer-paper__trip-planner
.trip-panner__total svg {
  font-size: 1em;
}

.MuiDrawer-paper__trip-planner .image + span {
  width: fit-content;
}

.trip-planner .MuiDrawer-paper {
  max-height: calc(100% - var(--header-height));
}

@media screen and (max-width: 900px) {
  .auth-popup 
  p.MuiDialogContentText-root{
    flex-direction: column !important;
  }

  .auth-popup
  .popup-right-section{
    width: 100%;
  }

  .why-cm-container > 
  .zig-zag {
    padding-bottom: 0 !important;
  }

  .why-cm-container > .zig-zag h2 {
    text-align: center;
  }

  .why-cm-container > 
  .zig-zag:nth-child(2n) > 
  div.MuiGrid-item:nth-child(1) {
    order: 1;
  }

  .product-main p {
  /*  font-size: 1.5rem;*/
    text-align: justify;
  }

  .product-main h6 {
    /* font-size: 1.75rem; */
  }
  
  .product-main h5 {
    /* font-size: 1.95rem; */
  }

  .product-main span.MuiTypography-caption {
    /*  font-size: 1.25rem;*/
  }

  .request-demo-grid .request-demo-layout {
    margin: auto;
    grid-template-columns: 1fr;
  }

  .request-demo-grid 
  .request-demo-layout > 
  .grid-node:last-of-type {
    text-align: center;
  }

  .request-demo-grid 
  .request-demo-layout > 
  .grid-node:last-of-type a {
    /*font-size: 1.45rem; */
  }
}

@media screen and (max-width: 600px) {
  .auth-popup
  p.MuiTypography-root ~ div{
    flex-direction: column;
  }

  .auth-popup
  p.MuiTypography-root ~ div > *:not(:last-child){
    margin-bottom: 8px !important;
  }

  .auth-popup
  p.MuiTypography-root ~ div > *:last-child{
    margin-left: 0px !important;
  }
  
   .title-legend {
      font-size: x-large !important; 
  }
  why

  .why-cm-container h2 {
    font-size: 2.15rem;
  }
  
  .why-cm-container h4 {
    font-size: 1rem;
  }

  .why-cm-container p,
  .why-cm-container h2,
  .why-cm-container h4 {
    text-align: left !important;
  }

  .why-cm-container .MuiGrid-item h2 {
    font-size: var(--fs-400);
  }
  /*
  .product-main h3 {
    font-size: 1.75rem;
  }

  .product-main h4 {
    font-size: 2rem;
  }

  .product-main h5,
  .product-main h6 {
    font-size: 1.55rem;
  }

  .product-main p {
    font-size: 1.25rem;
  }
*/
  .product-main .cm-product-mobile-image {
    /* width: 7.55rem; */
  }

  .product-main .cm-product-mobile-loop {
    /* width: 14.55rem; */
  }

  .product-main 
  .cm-solution-section 
  .MuiPaper-root {
    height: 80px
  } 

  .request-demo-grid
  .request-demo-layout > 
  .grid-node:first-of-type h4 {
    font-size: var(--fs-400);
  } 

  .request-demo-grid
  .request-demo-layout > 
  .grid-node:last-of-type a {
    font-size: var(--fs-400);
    padding: 16px 28px;
  }

 .search-bar-location {
   margin-right: -38px;
   /* padding-top: 68px; */
 }

  .route-planner--modal-content {
    width: 100%;
  }

  .route-planner--modal-content 
  div[aria-label="Starting Location"],
  .route-planner--modal-content 
  div[aria-label="Destination Location"]
  {
    width: 90%;
    margin-left: 8px;
  }  

  .MuiDialogActions-root.MuiAction-root-custom {
    gap: 0;
  }
}

.whatsapp_logo_container{
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 10;
}

.whatsapp_logo_container img{
  height: 60px;
}

.uppercase {
  text-transform: uppercase;
}

/* Feedback CSS */
.feedback-wrapper {
  border: 1px solid rgb(204, 200, 200);
  padding: 24px;
  margin: 12px 30px;
  border-radius: 12px;
}

.feedback-wrapper .session-feedback {
  font-size: 24px;
  font-weight: 600;
}

.feedback-wrapper .sub-text {
  color: #4B5563;
}

.feedback-wrapper .review {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap:12px;
}

.feedback-wrapper .head-sub {
  margin-bottom: 19px;
}

.feedback-wrapper .top {
  margin-bottom: 24px;
}

.feedback-wrapper .button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.feedback-wrapper .button-group .btn-wrapper {
    flex-grow:1;
}

.feedback-wrapper .button-group .btn-wrapper>label{
    width: 100%;
}

.feedback-wrapper .input-area {
  text-align: left;
}

.feedback-wrapper .top-inner {
  margin: 34px 0;
}

.feedback-wrapper .submit-section .option {
  margin: 22px 0;
}

.feedback-wrapper .submit-section img{
    object-fit: contain;
}

.section-feedback .image img {
  max-height: 350px;
  margin-top: 5rem;
}

.feedback-wrapper .feedback-type-wrapper {
  flex-wrap: wrap;
  gap: 12px;
}

.feedback-wrapper .feedback-type {
  padding: 10px;
  border: 1px solid var(--app-color) !important;
  border-radius: 12px !important;
  text-transform: capitalize;
  letter-spacing: 0 !important;
  font-size: 12px;
  flex-grow: 1;
  min-width: 97px;
}

@media screen and (max-width: 1060px) {
  .contact-Us-Main-Container {
    flex-wrap: wrap;
  }
  .contact-Us-Main-Container > div:nth-child(3) {
    width: 400px;
  }
}

@media screen and (max-width: 900px) {
  .contact-Us-Main-Container > div:nth-child(3) {
    width: 300px;
  }
}

@media screen and (max-width: 330px) {
  .contact-Us-Main-Container > div {
    width: 220px !important;
  }
}

.cross-badge > span {
  width: 15px;
  border-radius: 50%;
}

.cross-badge > span > svg {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}